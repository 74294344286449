<template>
  <div class="mx-list_element-info pa-4" v-if="!preloader">
    <strong class="mx-date-title">{{ $t(`company.opening_hours`) }}</strong>
    <div class="mx-date-day_of_week">
      <div class="mx-date-day_of_week__text">
        <strong v-for="element in opening_hours_elements">{{ $t(`company.day_of_week.${element}`) }}</strong>
      </div>
      <div class="mx-date-day_of_week__time">
        <span v-for="hour in opening_hours_elements" v-if="parseInt(opening[hour].open) === 1">
          {{opening[hour].from === 'null' ? '__:__' : opening[hour].from }} - {{ opening[hour].to === 'null' ? '__:__' : opening[hour].to }}
        </span>
        <span v-else class="mx-date-day_of_week__time-closed">{{ $t(`company.closed`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['opening_hours'],
  data() {
    return {
      preloader: true,
      opening: [],
      opening_hours_elements: ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so']
    }
  },
  watch: {
    opening_hours() {
      this.getHours()
    }
  },
  created() {
    this.getHours()
  },
  methods: {
    getHours() {
      this.opening = this.getOpeningHours(this.opening_hours)
      this.preloader = false
    },
    getOpeningHours(opening_hours) {
      if(typeof opening_hours === "object") {
        return opening_hours
      }
      if (opening_hours === undefined) {
        opening_hours = '{"mo":{"from":"null","to":"22:00","open":1},"di":{"from":"07:30","to":"22:00","open":1},"mi":{"from":"07:30","to":"22:00","open":1},"do":{"from":"07:30","to":"22:00","open":1},"fr":{"from":"07:30","to":"22:00","open":1},"sa":{"from":"null","to":"null","open":0},"so":{"from":"null","to":"null","open":0}}'
      }

      return JSON.parse(opening_hours)
    }
  }
}
</script>
