<template>
  <v-card flat class="pa-2">
    <v-card-title>
      <v-icon class="mt-n2">mdi-city-variant-outline</v-icon>
      <v-spacer></v-spacer>
      <span style="font-weight: 300;" class="mr-2">{{ subStr(company.company_name) }}</span>
      <strong>{{ company.company_name_short }}</strong>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="my-4 ">
      <strong>{{ $t('company.main_address') }}</strong> <br>
      <span>{{ address.street }}</span> <br>
      <span>{{ address.postcode }} {{ address.city }}</span> <br>
      <span>{{ address.country }}</span>
    </v-card-subtitle>
    <OpeningHours v-if="preloader === true" :opening_hours="address.opening_hours" />
  </v-card>
</template>

<script>
import OpeningHours from "@/components/Company/OpeningHours";

export default {
  props: {
    company: {
      type: Object,
      default: {}
    }
  },
  components: { OpeningHours },
  data() {
    return {
      preloader: false,
      cm: {},
      address: {},
    }
  },
  watch: {
    company() {
      this.getAddress()
    }
  },
  created() {
    this.getAddress()
  },
  methods: {
    getAddress() {
      if (this.company.addresses !== undefined) {
        this.address = this.company.addresses.find(address => address.is_main === 1)
        this.preloader = true
      }
    },
    subStr(str, size = 32) {
      if (!str) return '';
      str = str.toString();

      if (str.length <= size) {
        return str;
      }
      return str.substr(0, size) + '... ';
    }
  }
}
</script>
