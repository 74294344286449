<template>
  <div>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label=" $t('notice.table.search')"
        hide-details
        solo
        class="mb-6 elevation"
        height="53"
    />

    <template>
      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :search="search"
          :page.sync="page"
          hide-default-footer
          @click:row="getItem"
      >
        <template v-slot:item.gender="{ item }" >
          {{ $t(`company.person.table.gender.${item.gender}`) }}
        </template>
      </v-data-table>
      <div class="pt-2 d-flex justify-center">
        <div class="ml-auto">
          <v-pagination
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
        <div class="ml-auto" style="max-width: 60px;">
          <v-text-field
              :value="itemsPerPage"
              label=""
              type="number"
              min="-1"
              max="10"
              @input="itemsPerPage = parseInt($event, 10)"

          ></v-text-field>
        </div>
      </div>
    </template>

    <v-dialog
        v-model="dialog"
        max-width="800px"
        v-if="dialog"
    >
      <v-card>
        <v-toolbar color="primary" class="mb-4">
          <v-card-title>{{ item.first_name.toUpperCase() }} {{ item.last_name.toUpperCase() }}</v-card-title>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.phone }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.phone') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon :color="item.put_through === 1 ? 'red' : ''">mdi-arrow-decision</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{item.put_through === 0 ? $t('company.person.show.put_through.no') : $t('company.person.show.put_through.yes') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.put_through.title') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.email') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-account-arrow-up</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.position }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.position') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-city</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.address_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.short_name') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon v-if="item.gender === 'divers' || item.gender === 'indefinite'">
                      mdi-gender-transgender
                    </v-icon>
                    <v-icon color="blue" v-if="item.gender === 'male'">
                      mdi-gender-male
                    </v-icon>
                    <v-icon color="red" v-if="item.gender === 'female'">
                      mdi-gender-female
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(`company.person.show.gender.${item.gender}`) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('company.person.show.gender.title') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
export default {
  props: {
    contact_persons: {
      type: Array,
      default: []
    }
  },
  watch: {
    contact_persons() {
      this.getItems()
    },
    'search.search'() {
      if (this.search.search === '') {
        this.getItems()
      }
    }
  },
  data() {
    return {
      dialog: false,
      isMobile: true,
      height: 0,
      items: [],
      item: {},
      item_id: 1,
      search: '',
      headers:[
        { text: this.$t('company.person.table.short_name'), value: 'address_name'},
        { text: this.$t('company.person.table.gender.title'), value: 'gender'},
        { text: this.$t('company.person.table.first_name'), value: 'first_name', width: '200px' },
        { text: this.$t('company.person.table.last_name'), value: 'last_name',  },
        { text: this.$t('company.person.table.position'), value: 'position', },
        { text: this.$t('company.person.table.phone'), value: 'phone',  },
        { text: this.$t('company.person.table.email'), value: 'email',  },
        { text: '', value: 'action',sortable: false, width: '150px' },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    getItem(item) {
      this.item = item
      this.dialog = true
    },
    getItems() {
      let addresses = this.$store.getters.company.addresses
      this.contact_persons.map(person => {
        return addresses.map(address => {
          if (person.address_id === address.id) {
            person['address_name'] = address.address_short_name
          }
          return person
        })
      })
      this.items = this.contact_persons
    }
  }
}
</script>
